import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Avatar, Badge, Box, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, Flex, GridItem, HStack, IconButton, Image, SimpleGrid, Tag, TagLabel, TagLeftIcon, Text, VStack, Wrap, WrapItem, useDisclosure } from '@chakra-ui/react';
import { PiPencilCircleDuotone, PiPlusCircleDuotone, PiTextColumnsDuotone } from 'react-icons/pi';
import { toggleBio } from '../../../redux/slices/sectionEditSlice';
import { useDispatch } from 'react-redux';
import BioCardEditable from '../../EditSections/BioEditable';
import rehypeRaw from 'rehype-raw';
import Markdown from 'react-markdown';
import UserCardEditable from '../../EditSections/UserCardEditable';
import { getLinkDetails, getTextColor, openLink, titleCase } from '../../../helper/random.helper';
import CircleTextAnimation from '../../CircleTextAnimation/CircleTextAnimation';
import ShareableDiv from '../../../atoms/SharableDiv';
import { HiOutlineLocationMarker } from 'react-icons/hi';
import { BsHourglassSplit } from 'react-icons/bs';
import { LuMail, LuShare2 } from 'react-icons/lu';
import { TbTools } from 'react-icons/tb';
import ExperienceEdit from '../../EditSections/editExperience/ExperienceEdit';
import useEditExperience from '../../EditSections/editExperience/useEditExperience';
import Timeline from '../BasicTemplate/Timeline';
import useEditProject from '../../EditSections/editProjects/useEditProject';
import ProjectEdit from '../../EditSections/editProjects/ProjectEdit';
import CoreSkillsEdit from '../../EditSections/editCoreSkills/CoreSkillsEdit';
import useEditCoreSkills from '../../EditSections/editCoreSkills/useEditCoreSkills';
import useEditSoftSkills from '../../EditSections/editSoftSkills/useEditSoftSkills';
import SoftSkillsEdit from '../../EditSections/editSoftSkills/SoftSkillsEdit';
import EducationEdit from '../../EditSections/editEducation/EducationEdit';
import AchievementsEdit from '../../EditSections/editAchievements/AchievementEdit';
import useEditEducation from '../../EditSections/editEducation/useEditEducation';
import useEditAchievements from '../../EditSections/editAchievements/useEditAchievements';


const OverlayPersona = ({ UserData: extUser }) => {
  const { isLoggedIn } = useSelector(state => state.status);

  const { isOpen: isUserCardOpen, onOpen: onUserCardOpen, onClose: onUserCardClose } = useDisclosure();
  const dispatch = useDispatch();
  const { onOpen: onOpenWorkDescription, isOpen: isOpenWorkDescription, onClose: onCloseWorkDescription } = useDisclosure();
  const editExp = useEditExperience({ extUser });
  const {
    user,
    reset: resetExp,
    SelectedWorkObj,
    setSelectedWorkObj,
    setEditingWorkObjIndex,
    resetFormWithWorkIdData,
    InitialFormValue: InitialValueEdp,
    onOpen: onOpenEditExp,
  } = editExp;

  const { onOpen: onOpenProjectDescription, isOpen: isOpenProjectDescription, onClose: onCloseProjectDescription } = useDisclosure();
  const editProject = useEditProject({ extUser });
  const {
    setEditingProjectObjIndex,
    resetFormWithProjectIdData,
    onOpen: onOpenEditProject,
    setSelectedProjectObj,
    SelectedProjectObj,
  } = editProject;

  const editCoreSkills = useEditCoreSkills({ extUser });

  const {
    onOpen: onOpenCoreSkills,
  } = editCoreSkills;

  const editSoftSkills = useEditSoftSkills({ extUser });
  const {
    onOpen: onOpenSoftSkills,
  } = editSoftSkills;

  const editAchievements = useEditAchievements({ extUser });
  const {
    onOpen: onOpenEditAchievements,
    resetFormWithAchievementIdData,
    setEditingAchievementObjIndex,
  } = editAchievements;


  const editEducation = useEditEducation({ extUser });

  const {
    reset,
    resetFormWithEducationIdData,
    onOpen: onOpenEditEducation,
    setEditingEducationObjIndex,
    InitialFormValue
  } = editEducation;



  return (
    <Flex background={"#FFFCF5"} padding={isLoggedIn ? '100px 10px 50px 10px' : '20px 10px 20px 10px'} direction={'column'} justifyContent="center" gap={3}>
      {user?.username && (
        <>
          <Flex direction="column" justifyContent="center" gap={3}>
            {/* start of hero image + introduction */}
            <Flex
              h={["auto"]}
              direction={{ base: 'column', md: 'row' }} alignItems={"flex-end"} gap={3}>
              <Flex
                w={["100%", "calc(50% - 20px)"]}
                minH={"400px"}
                minWidth={{ base: "300px", md: "380px" }}
                position="relative" direction="column" alignItems="center" gap={3}>
                <Text color={user?.theme[1]} fontSize={{ base: "350px", md: "350px" }} lineHeight={0.8} opacity={0.6} fontWeight={700} position={"absolute"} left={0} bottom={20}>{user.fullname.charAt(0).toUpperCase()}</Text>
                {user.fullname.split(' ').length > 1 ? < Text color={user?.theme[1]} fontSize={{ base: "350px", md: "350px" }} opacity={0.6} fontWeight={700} lineHeight={0.8} position={"absolute"} right={0} bottom={0}>{user.fullname.split(' ')?.[1].charAt(0).toUpperCase()}</Text> : null}
                {user?.DPUrl ? <Image
                  src={user?.DPUrl}
                  borderRadius={"none"}
                  width={"full"}
                  height={"full"}
                  objectFit={"cover"}
                  objectPosition={"center top"}
                /> : null}

                {!extUser && (
                  <IconButton
                    icon={<PiPencilCircleDuotone size={"18px"} />}
                    position="absolute"
                    left={"10px"}
                    top={"10px"}
                    zIndex={99}
                    onClick={onUserCardOpen}
                    aria-label="Edit User"
                    size="sm"
                    variant="ghost"
                  />
                )}
              </Flex>
              <Box minWidth={["100%", "calc(50% - 20px)"]} position={"relative"}
                display={"flex"} flexDirection={"column"} justifyContent={"center"} gap={"10px"}>
                <Box display={'flex'} flexDirection={'column'} alignItems={"flex-end"} justifyContent={"flex-end"} gap={'10px'} h={"100%"}>
                  <Text p={"5px 5px 5px 50px"} color={getTextColor(user?.theme[1]) ? '#fff' : '#000'} background={user?.theme[1]} lineHeight={0.8} fontWeight="500" textAlign="right">
                    {user?.availability}
                  </Text>
                  <Text mb={["20px", "20px", "0"]} lineHeight={1} fontSize={['6xl', '6xl', '6xl']} fontWeight="900" textAlign="right">
                    {titleCase(user?.fullname)}
                  </Text>
                  <Box display={'flex'} flexDirection={'column'} alignItems={"flex-end"} justifyContent={"flex-end"} gap={'10px'}>
                    <HStack p="5px" color={getTextColor(user?.theme[1]) ? '#fff' : '#000'} background={user?.theme[1]} justifyContent={"space-between"} w="full">
                      {!extUser && <Box cursor={'pointer'} onClick={() => dispatch(toggleBio())}>
                        <PiPencilCircleDuotone size="18px" />
                      </Box>}
                      <Text lineHeight={0.8} fontSize={['xl', '2xl']} w="full" fontWeight="500" textAlign="right">
                        Introduction
                      </Text>
                    </HStack>

                    <Text textAlign={"right"}>
                      <Markdown className="markdown" rehypePlugins={[rehypeRaw]}>
                        {user?.bio}
                      </Markdown>
                    </Text>
                    {/* start of userInfo */}
                    <Flex
                      gap={3} direction={'row'} alignItems="center" wrap="wrap" justifyContent="flex-end">

                      <Tag
                        size="md"
                        border="none"
                        borderRadius={"none"}
                        color={getTextColor(user?.theme[1]) ? '#fff' : '#000'}
                        background={user?.theme[1]}

                      >
                        <TagLeftIcon as={TbTools} />
                        {user?.role}
                      </Tag>

                      {user?.location ? (
                        <Tag
                          size="md"
                          border="none"
                          backgroundColor="transparent"
                        >
                          <TagLeftIcon as={HiOutlineLocationMarker} />
                          {user?.location}
                        </Tag>
                      ) : null}
                      {user?.experience ? (
                        <Tag
                          size="md"
                          border="none"
                          backgroundColor="transparent"
                        >
                          <TagLeftIcon as={BsHourglassSplit} />
                          {user?.experience} years
                        </Tag>
                      ) : null}
                      {user?.links?.map((item, index) => {
                        const { icon } = getLinkDetails(item, 14);
                        return (
                          <IconButton
                            key={index}
                            icon={icon}
                            onClick={() => openLink(item)}
                            aria-label={`link-${index}`}
                            size="sm"
                            p={0}
                            border="none"
                            variant="ghost"
                          />
                        );
                      })}
                      {!user?.private_email ? <Tag
                        size="md"
                        onClick={() => window.location.href = `mailto:${user?.email}`}
                        border="none"
                        backgroundColor="transparent"
                      >
                        <TagLeftIcon as={LuMail} />
                        {user?.email}
                      </Tag> : null}
                    </Flex>
                    {/* end of userInfo */}
                  </Box>
                </Box>
              </Box>
              <BioCardEditable extUser={extUser} />
            </Flex>
            <UserCardEditable isOpen={isUserCardOpen} onClose={onUserCardClose} onOpen={onUserCardOpen} />
            {/* End of hero image + introduction */}
            {/* Start of Body Section */}
            <SimpleGrid columns={[1, 1, 2, 3, 4]} gap={10} mt={5}>
              {/* Work Grid */}
              <GridItem>
                {/* start of work section */}
                {user?.work?.length ? <Flex
                  position={'relative'}
                  width={"100%"}
                  minWidth={{ base: '100%' }}
                  direction={"column"}
                  gap={"30px"}
                  alignItems={"flex-start"}
                  py={4}
                >
                  <HStack background={user?.theme[1]} color={getTextColor(user?.theme[1]) ? "#fff" : "#000"} w={"full"} px="5px">
                    <Text fontSize={"xl"} fontWeight={"500"}>Work</Text>
                    {!extUser && <Box
                      cursor={"pointer"}
                      onClick={() => {
                        onOpenEditExp();
                        resetExp(InitialValueEdp);
                      }}
                    >
                      <PiPlusCircleDuotone size="18px" />
                    </Box>}
                  </HStack>
                  {user?.work.map((item, index) => {
                    return (
                      <VStack
                        w="100%"
                        gap={3}
                        alignItems={"flex-start"}
                        position={'relative'}
                        key={index}>
                        <Text fontWeight={"500"}
                          fontSize={"sm"}>{item.company}</Text>
                        <Text
                          width={'fit-content'}
                          mt={"-3px"}
                        >
                          {titleCase(item.title)}
                        </Text>
                        <Markdown className="markdown" rehypePlugins={[rehypeRaw]}>
                          {item.summary}
                        </Markdown>
                        <HStack wrap={"wrap"} gap={2}>
                          {item?.images.length > 0 && item.images.map((item, index) => {
                            return (
                              <Image src={item} key={index} maxHeight={"100px"} maxW={"100px"} objectFit={"contain"} />
                            )
                          })}
                        </HStack>
                        <HStack gap={2} w="100%" alignItems={"start"}>
                          <Timeline date={item.years} />
                          <Box
                            cursor={"pointer"}
                            onClick={() => {
                              onOpenWorkDescription();
                              setSelectedWorkObj(item);
                            }}
                          >
                            <PiTextColumnsDuotone size="16px" />
                          </Box>
                          {!extUser &&
                            <Box
                              cursor={"pointer"}
                              onClick={() => {
                                setEditingWorkObjIndex(index);
                                onOpenEditExp();
                                setSelectedWorkObj(item);
                                resetFormWithWorkIdData(item)
                              }}
                            >
                              <PiPencilCircleDuotone size="18px" />
                            </Box>
                          }
                        </HStack>
                      </VStack>
                    );
                  })}

                </Flex> : null}
                <ExperienceEdit {...editExp} />
                <Drawer
                  blockScrollOnMount={false}
                  isOpen={isOpenWorkDescription}
                  onOpen={onOpenWorkDescription}
                  onClose={() => {
                    onCloseWorkDescription();
                    setSelectedWorkObj(null);
                  }}
                >
                  <DrawerOverlay />
                  <DrawerContent
                    border={"2px solid"}
                    borderColor={getTextColor(user?.theme[1]) ? "#fff" : "#000"}
                    background={user?.theme[1]} zIndex={9999} maxWidth={{ base: "90%", md: "50%" }}>
                    <DrawerCloseButton color={getTextColor(user?.theme[1]) ? "#fff" : "#000"} />
                    <DrawerHeader color={getTextColor(user?.theme[1]) ? "#fff" : "#000"} textAlign={"center"} fontSize={'sm'} fontWeight={'500'} borderBottom={"2px solid"}
                      borderColor={getTextColor(user?.theme[1]) ? "#fff" : "#000"}>
                      {SelectedWorkObj?.company}
                    </DrawerHeader>
                    <DrawerBody color={getTextColor(user?.theme[1]) ? "#fff" : "#000"} className="app-scroll" overflow={'auto'} p={"20px 40px"} width={"100%"}>
                      {SelectedWorkObj?.images.length > 0 && <HStack className="app-scroll" mt={2} mb={4} overflowX={"auto"} gap={2}>
                        {SelectedWorkObj.images.map((item, index) => {
                          return (
                            <Image src={item} key={index} maxHeight={"350px"} objectFit={"contain"} />
                          )
                        })}
                      </HStack>
                      }
                      <Text mb={3} >{titleCase(SelectedWorkObj?.title)}</Text>
                      <Markdown className="markdown" rehypePlugins={[rehypeRaw]}>
                        {SelectedWorkObj?.description}
                      </Markdown>
                    </DrawerBody>
                  </DrawerContent>
                </Drawer>
                {/* end of work section */}
              </GridItem>

              <GridItem>
                {/* start of projects section */}
                {user?.other_projects?.length ? <Flex
                  position={'relative'}
                  width={"100%"}
                  minWidth={{ base: '100%' }}
                  alignItems={"flex-start"}
                  direction="column"
                  py={4}
                  gap={"30px"}
                >
                  <HStack background={user?.theme[1]} color={getTextColor(user?.theme[1]) ? "#fff" : "#000"} w={"full"} px="5px">
                    <Text fontSize={"xl"} fontWeight={"500"}>Projects</Text>
                    {!extUser && <Box cursor={'pointer'} onClick={onOpenEditProject}>
                      <PiPlusCircleDuotone size="18px" />
                    </Box>}
                  </HStack>
                  {user?.other_projects.map((item, index) => {
                    return (
                      <VStack
                        w={"100%"}
                        gap={3}
                        alignItems={"flex-start"}
                        position={'relative'}
                        key={index}>
                        <Text fontWeight={"500"}
                          fontSize={"sm"}
                          width={'fit-content'}
                        >
                          {item.title}
                        </Text>

                        <Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'}>
                          <Markdown className="markdown" rehypePlugins={[rehypeRaw]}>
                            {item.summary}
                          </Markdown>
                        </Box>
                        <HStack wrap={"wrap"} gap={2}>
                          {item?.images.length > 0 && item.images.map((item, index) => {
                            return (
                              <Image src={item} key={index} maxHeight={"100px"} maxWidth="100px" objectFit={"contain"} />
                            )
                          })}
                        </HStack>
                        <HStack gap={2} w="100%">
                          <Timeline date={String(item.year)} />
                          <Box
                            cursor={"pointer"}
                            onClick={() => {
                              onOpenProjectDescription();
                              setSelectedProjectObj(item);
                            }}
                          >
                            <PiTextColumnsDuotone size="16px" />
                          </Box>
                          {!extUser &&
                            <Box
                              cursor={"pointer"}
                              onClick={() => {
                                setEditingProjectObjIndex(index);
                                onOpenEditProject();
                                setSelectedProjectObj(item);
                                resetFormWithProjectIdData(item)
                              }}
                            >
                              <PiPencilCircleDuotone size="18px" />
                            </Box>
                          }
                        </HStack>
                      </VStack>
                    );
                  })}
                </Flex> : null}
                <ProjectEdit {...editProject} />
                <Drawer
                  blockScrollOnMount={false}
                  isOpen={isOpenProjectDescription}
                  onOpen={onOpenProjectDescription}
                  onClose={() => {
                    onCloseProjectDescription();
                    setSelectedProjectObj(null);
                  }}
                >
                  <DrawerOverlay />
                  <DrawerContent
                    border={"2px solid"}
                    borderColor={getTextColor(user?.theme[1]) ? "#fff" : "#000"}
                    background={user?.theme[1]} zIndex={9999} maxWidth={{ base: "90%", md: "50%" }}>
                    <DrawerCloseButton color={getTextColor(user?.theme[1]) ? "#fff" : "#000"} />
                    <DrawerHeader color={getTextColor(user?.theme[1]) ? "#fff" : "#000"} textAlign={"center"} fontSize={'sm'} fontWeight={'500'} borderBottom={"2px solid"} borderColor={getTextColor(user?.theme[1]) ? "#fff" : "#000"}>
                      {SelectedProjectObj?.title}
                    </DrawerHeader>
                    <DrawerBody color={getTextColor(user?.theme[1]) ? "#fff" : "#000"} className="app-scroll" overflow={'auto'} p={"20px 40px"} width={"100%"}>
                      {SelectedProjectObj?.images.length > 0 && <HStack className="app-scroll" mt={2} mb={4} overflowX={"auto"} gap={2}>
                        {SelectedProjectObj.images.map((item, index) => {
                          return (
                            <Image src={item} key={index} maxHeight={"350px"} objectFit={"contain"} />
                          )
                        })}
                      </HStack>
                      }
                      <Markdown className="markdown" rehypePlugins={[rehypeRaw]}>
                        {SelectedProjectObj?.description}
                      </Markdown>
                    </DrawerBody>
                  </DrawerContent>
                </Drawer>
                {/* end of projects section */}
              </GridItem>

              <GridItem>
                <VStack alignItems={"flex-start"}>
                  {user?.achievements?.length ? <Flex
                    position={'relative'}
                    height={"fit-content"}
                    alignItems={"flex-start"}
                    direction="column"
                    py={4}
                    gap="20px"
                  >
                    <HStack background={user?.theme[1]} color={getTextColor(user?.theme[1]) ? "#fff" : "#000"} w={"full"} px="5px">
                      <Text fontSize={"xl"} fontWeight={"500"}>Achievements</Text>
                      {!extUser && <Box cursor={'pointer'} onClick={onOpenEditAchievements}>
                        <PiPlusCircleDuotone size="18px" />
                      </Box>}
                    </HStack>
                    {user?.achievements.map((item, index) => {
                      return (
                        <VStack
                          gap={3}
                          alignItems={"flex-start"}
                          width={"100%"}
                          position={'relative'}
                          key={index}>
                          <Text
                            width={'fit-content'}
                          >
                            {item.title}
                          </Text>
                          <HStack gap={2} w="100%">
                            <Timeline date={String(item.year)} />
                            {!extUser &&
                              <Box
                                cursor={"pointer"}
                                onClick={() => {
                                  setEditingAchievementObjIndex(index);
                                  onOpenEditAchievements();
                                  resetFormWithAchievementIdData(item)
                                }}
                              >
                                <PiPencilCircleDuotone size="18px" />
                              </Box>
                            }
                          </HStack>
                        </VStack>
                      );
                    })}
                  </Flex> : null}
                  <AchievementsEdit {...editAchievements} />
                  {user?.education?.length ? <Flex
                    position={'relative'}
                    direction={"column"}
                    gap="20px"
                    maxWidth={{ base: "100%" }}
                    py={4}
                  >
                    <HStack background={user?.theme[1]} color={getTextColor(user?.theme[1]) ? "#fff" : "#000"} w={"full"} px="5px">
                      <Text fontSize={"xl"} fontWeight={"500"}>Education</Text>
                      {!extUser && <Box
                        cursor={"pointer"}
                        onClick={() => {
                          onOpenEditEducation();
                          reset(InitialFormValue);
                        }}
                      >
                        <PiPlusCircleDuotone size="18px" />
                      </Box>}
                    </HStack>
                    {user?.education.map((item, index) => {
                      return (
                        <VStack
                          gap={3}
                          alignItems={"flex-start"}
                          position={'relative'}
                          key={index}>
                          <Text fontWeight={"500"}
                            fontSize={"sm"} >{item.name}</Text>
                          <Text className="font_cursive2" fontStyle={"italic"}
                            width={'fit-content'}
                            mt={"-3px"}
                          >
                            {item.course}
                          </Text>
                          <Markdown className="markdown" rehypePlugins={[rehypeRaw]}>
                            {item.description}
                          </Markdown>
                          <HStack gap={2} w="100%" alignItems={"start"}>
                            <Timeline date={String(item.year)} />
                            {!extUser &&
                              <Box
                                cursor={"pointer"}
                                onClick={() => {
                                  setEditingEducationObjIndex(index);
                                  onOpenEditEducation();
                                  resetFormWithEducationIdData(item)
                                }}
                              >
                                <PiPencilCircleDuotone size="18px" />
                              </Box>
                            }
                          </HStack>
                        </VStack>
                      );
                    })}
                  </Flex> : null}
                  <EducationEdit {...editEducation} />
                </VStack>
              </GridItem>
              <GridItem>
                <VStack alignItems={"flex-start"}>
                  {user?.soft_skills?.length ? <Box
                    position={'relative'}
                    height={'fit-content'}
                    py={4}
                  >
                    <HStack background={user?.theme[1]} color={getTextColor(user?.theme[1]) ? "#fff" : "#000"} w={"full"} px="5px">
                      <Text fontSize={"xl"} fontWeight={'500'} px={"7px"}>Soft Skills</Text>
                      {!extUser && <Box cursor={'pointer'} onClick={onOpenSoftSkills}>
                        <PiPlusCircleDuotone size="18px" />
                      </Box>}
                    </HStack>
                    <Wrap spacing={0} mt={'10px'}>
                      {user?.soft_skills?.map((item, index) => (
                        <WrapItem key={index}>
                          <Tag
                            borderRadius="full"
                            fontWeight={400}
                            backgroundColor="transparent"
                          >
                            <TagLabel fontSize="sm" fontWeight={500}>{item}</TagLabel>
                          </Tag>
                        </WrapItem>
                      ))}
                    </Wrap>
                  </Box> : null}
                  <SoftSkillsEdit {...editSoftSkills} />
                  {user?.core_skills?.length ? <Box
                    position={'relative'}
                    height={'fit-content'}
                    py={2}
                  >
                    <HStack background={user?.theme[1]} color={getTextColor(user?.theme[1]) ? "#fff" : "#000"} w={"full"} px="5px">
                      <Text fontSize={"xl"} fontWeight={'500'} px={"7px"}>Core Skills</Text>
                      {!extUser && <Box cursor={'pointer'} onClick={onOpenCoreSkills}>
                        <PiPlusCircleDuotone size="18px" />
                      </Box>}
                    </HStack>
                    <Wrap spacing={0} mt={'10px'}>
                      {user?.core_skills?.map((item, index) => (
                        <WrapItem key={index}>
                          <Tag
                            fontWeight={400}
                            backgroundColor="transparent"
                          >
                            <TagLabel fontSize="sm" fontWeight={500}>{item}</TagLabel>
                          </Tag>
                        </WrapItem>
                      ))}
                    </Wrap>
                  </Box> : null}
                  <CoreSkillsEdit {...editCoreSkills} />
                </VStack>
              </GridItem>

            </SimpleGrid>
            {/* end of Body Section */}

          </Flex >
        </>
      )
      }
    </Flex >
  )
}

export default OverlayPersona;