import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Avatar, Badge, Box, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, Flex, HStack, IconButton, Image, Tag, TagLabel, TagLeftIcon, Text, VStack, Wrap, WrapItem, useDisclosure } from '@chakra-ui/react';
import { PiPencilCircleDuotone, PiPlusCircleDuotone, PiTextColumnsDuotone } from 'react-icons/pi';
import { userstatus } from '../../../utils/constants';
import { toggleBio } from '../../../redux/slices/sectionEditSlice';
import { useDispatch } from 'react-redux';
import BioCardEditable from '../../EditSections/BioEditable';
import rehypeRaw from 'rehype-raw';
import Markdown from 'react-markdown';
import UserCardEditable from '../../EditSections/UserCardEditable';
import { getLinkDetails, getTextColor, openLink, titleCase } from '../../../helper/random.helper';
import CircleTextAnimation from '../../CircleTextAnimation/CircleTextAnimation';
import ShareableDiv from '../../../atoms/SharableDiv';
import { HiOutlineLocationMarker } from 'react-icons/hi';
import { BsHourglassSplit } from 'react-icons/bs';
import { LuMail, LuShare2 } from 'react-icons/lu';
import { TbTools } from 'react-icons/tb';
import ExperienceEdit from '../../EditSections/editExperience/ExperienceEdit';
import useEditExperience from '../../EditSections/editExperience/useEditExperience';
import Timeline from '../BasicTemplate/Timeline';
import useEditProject from '../../EditSections/editProjects/useEditProject';
import ProjectEdit from '../../EditSections/editProjects/ProjectEdit';
import CoreSkillsEdit from '../../EditSections/editCoreSkills/CoreSkillsEdit';
import useEditCoreSkills from '../../EditSections/editCoreSkills/useEditCoreSkills';
import useEditSoftSkills from '../../EditSections/editSoftSkills/useEditSoftSkills';
import SoftSkillsEdit from '../../EditSections/editSoftSkills/SoftSkillsEdit';
import EducationEdit from '../../EditSections/editEducation/EducationEdit';
import AchievementsEdit from '../../EditSections/editAchievements/AchievementEdit';
import useEditEducation from '../../EditSections/editEducation/useEditEducation';
import useEditAchievements from '../../EditSections/editAchievements/useEditAchievements';


const Bold = ({ UserData: extUser }) => {
  const { isLoggedIn } = useSelector(state => state.status);

  const { isOpen: isUserCardOpen, onOpen: onUserCardOpen, onClose: onUserCardClose } = useDisclosure();
  const dispatch = useDispatch();
  const { onOpen: onOpenWorkDescription, isOpen: isOpenWorkDescription, onClose: onCloseWorkDescription } = useDisclosure();
  const editExp = useEditExperience({ extUser });
  const {
    user,
    reset: resetExp,
    SelectedWorkObj,
    setSelectedWorkObj,
    setEditingWorkObjIndex,
    resetFormWithWorkIdData,
    InitialFormValue: InitialValueEdp,
    onOpen: onOpenEditExp,
  } = editExp;

  const { onOpen: onOpenProjectDescription, isOpen: isOpenProjectDescription, onClose: onCloseProjectDescription } = useDisclosure();
  const editProject = useEditProject({ extUser });
  const {
    setEditingProjectObjIndex,
    resetFormWithProjectIdData,
    onOpen: onOpenEditProject,
    setSelectedProjectObj,
    SelectedProjectObj,
  } = editProject;

  const editCoreSkills = useEditCoreSkills({ extUser });

  const {
    onOpen: onOpenCoreSkills,
  } = editCoreSkills;

  const editSoftSkills = useEditSoftSkills({ extUser });
  const {
    onOpen: onOpenSoftSkills,
  } = editSoftSkills;

  const editAchievements = useEditAchievements({ extUser });
  const {
    onOpen: onOpenEditAchievements,
    resetFormWithAchievementIdData,
    setEditingAchievementObjIndex,
  } = editAchievements;


  const editEducation = useEditEducation({ extUser });

  const {
    reset,
    resetFormWithEducationIdData,
    onOpen: onOpenEditEducation,
    setEditingEducationObjIndex,
    InitialFormValue
  } = editEducation;


  return (
    <Flex className='font_bebas' padding={isLoggedIn ? '100px 10px 50px 10px' : '20px 10px 20px 10px'} direction={'column'} alignItems={"center"} justifyContent="center" gap={3}>
      {user?.username && (
        <>
          <Flex
            maxW={"1000px"}
            direction={{ base: 'column' }} justifyContent="flex-start" alignItems={"center"} gap={3}>
            <Box position={"relative"} w="100%">
              {!extUser && (
                <Box position="absolute"
                  left={"0px"}
                  top={"0px"}
                  zIndex={9999}
                  cursor={"pointer"}
                  onClick={onUserCardOpen}>
                  <PiPencilCircleDuotone size={"18px"} />
                </Box>
              )}
              <Text color={user?.theme[1]} fontSize={"lg"} marginBottom={"-10px"} fontWeight={"900"} textAlign={"center"}>
                {user?.role}
              </Text>
            </Box>

            <Text
              color={user?.theme[1]} fontSize={['80px', "140px", '150px', '170px']} fontWeight="900" textAlign="center" lineHeight={0.8}>
              {titleCase(user?.fullname)}
            </Text>
            {user?.DPUrl ? <Image
              src={user?.DPUrl}
              borderRadius={"none"}
              width={{ base: "50%", sm: "30%", md: "30%" }}
              marginTop={{ base: "-40px", md: "-70px" }}
              objectFit={"contain"}
              objectPosition={"center top"}
            /> : null}
            <HStack
              w="100%"
              wrap="wrap"

              alignItems={"center"} justifyContent={"center"}>
              {!user?.private_email ?
                <Tag
                  size="md"
                  onClick={() => window.location.href = `mailto:${user?.email}`}
                  border="none"
                  color={user?.theme[1]}
                  backgroundColor="transparent"
                >
                  <TagLeftIcon as={LuMail} />
                  {user?.email}
                </Tag> : null}
              {user?.experience ? (
                <Tag
                  size="md"
                  border="none"
                  color={user?.theme[1]}
                  backgroundColor="transparent"
                >
                  <TagLeftIcon as={BsHourglassSplit} />
                  {user?.experience} years
                </Tag>
              ) : null}
              <Tag
                size="md"
                border="none"
                backgroundColor="transparent"
                color={user?.theme[1]}
              >
                {user?.availability}
              </Tag>
              {user?.location ? (
                <Tag
                  size="md"
                  border="none"
                  backgroundColor="transparent"
                  color={user?.theme[1]}
                >
                  <TagLeftIcon as={HiOutlineLocationMarker} />
                  {user?.location}
                </Tag>
              ) : null}
              <HStack>
                {user?.links?.map((item, index) => {
                  const { icon } = getLinkDetails(item, 14);
                  return (
                    <IconButton
                      key={index}
                      color={user?.theme[1]}
                      icon={icon}
                      onClick={() => openLink(item)}
                      aria-label={`link-${index}`}
                      size="sm"
                      p={0}
                      border="none"
                      variant="ghost"
                    />
                  );
                })}
              </HStack>
            </HStack>
            <Box textAlign={"center"} position={"relative"} display={'flex'} flexDirection={'column'} width={{ base: "100%", md: "80%" }} justifyContent={"flex-end"} gap={'10px'} h={"100%"}>
              <Markdown className="markdown" rehypePlugins={[rehypeRaw]}>
                {user?.bio}
              </Markdown>
              {!extUser && <Box cursor={'pointer'} position={'absolute'} top={"-30px"} right={"0px"} onClick={() => dispatch(toggleBio())}>
                <PiPencilCircleDuotone size="18px" />
              </Box>}
            </Box>

            <BioCardEditable extUser={extUser} />
            <HStack wrap={{ base: "wrap", md: "nowrap" }} spacing={4} justifyContent={{ base: "center", md: "flex-start" }} alignItems={"flex-start"}>
              {user?.work?.length ? <Flex
                position={'relative'}
                w={{ base: '100%', md: "auto" }}

                direction={"column"}
                gap={"30px"}
                alignItems={"flex-start"}
                p={3}
              >
                <HStack>
                  <Text color={user?.theme[1]} fontSize={"xl"} fontWeight={"500"}>Work</Text>
                  {!extUser && <Box
                    cursor={"pointer"}
                    onClick={() => {
                      onOpenEditExp();
                      resetExp(InitialValueEdp);
                    }}
                  >
                    <PiPlusCircleDuotone size="18px" />
                  </Box>}
                </HStack>
                {user?.work.map((item, index) => {
                  return (
                    <VStack
                      gap={3}
                      alignItems={"flex-start"}
                      position={'relative'}
                      key={index}>
                      <Text fontWeight={"500"}
                        fontSize={"sm"}>{item.company}</Text>
                      <Text
                        width={'fit-content'}
                        mt={"-3px"}
                      >
                        {titleCase(item.title)}
                      </Text>
                      <Markdown className="markdown" rehypePlugins={[rehypeRaw]}>
                        {item.summary}
                      </Markdown>
                      <HStack wrap={"wrap"} gap={2}>
                        {item?.images.length > 0 && item.images.map((item, index) => {
                          return (
                            <Image src={item} key={index} maxHeight={"100px"} maxWidth="100px" objectFit={"contain"} />
                          )
                        })}
                      </HStack>
                      <HStack gap={2} w="100%" alignItems={"start"}>
                        <Timeline date={item.years} />
                        <Box
                          cursor={"pointer"}
                          onClick={() => {
                            onOpenWorkDescription();
                            setSelectedWorkObj(item);
                          }}
                        >
                          <PiTextColumnsDuotone size="16px" />
                        </Box>
                        {!extUser &&
                          <Box
                            cursor={"pointer"}
                            onClick={() => {
                              setEditingWorkObjIndex(index);
                              onOpenEditExp();
                              setSelectedWorkObj(item);
                              resetFormWithWorkIdData(item)
                            }}
                          >
                            <PiPencilCircleDuotone size="18px" />
                          </Box>
                        }
                      </HStack>
                    </VStack>
                  );
                })}

              </Flex> : null}
              <ExperienceEdit {...editExp} />
              <Drawer
                blockScrollOnMount={false}
                isOpen={isOpenWorkDescription}
                onOpen={onOpenWorkDescription}
                onClose={() => {
                  onCloseWorkDescription();
                  setSelectedWorkObj(null);
                }}
              >
                <DrawerOverlay />
                <DrawerContent
                  border={"2px solid"}
                  borderColor={getTextColor(user?.theme[1]) ? "#fff" : "#000"}
                  background={user?.theme[1]} zIndex={9999} maxWidth={{ base: "90%", md: "50%" }}>
                  <DrawerCloseButton color={getTextColor(user?.theme[1]) ? "#fff" : "#000"} />
                  <DrawerHeader color={getTextColor(user?.theme[1]) ? "#fff" : "#000"} textAlign={"center"} fontSize={'sm'} fontWeight={'500'} borderBottom={"2px solid"}
                    borderColor={getTextColor(user?.theme[1]) ? "#fff" : "#000"}>
                    {SelectedWorkObj?.company}
                  </DrawerHeader>
                  <DrawerBody color={getTextColor(user?.theme[1]) ? "#fff" : "#000"} className="app-scroll" overflow={'auto'} p={"20px 40px"} width={"100%"}>
                    {SelectedWorkObj?.images.length > 0 && <HStack className="app-scroll" mt={2} mb={4} overflowX={"auto"} gap={2}>
                      {SelectedWorkObj.images.map((item, index) => {
                        return (
                          <Image src={item} key={index} maxHeight={"350px"} objectFit={"contain"} />
                        )
                      })}
                    </HStack>
                    }
                    <Text mb={3} >{titleCase(SelectedWorkObj?.title)}</Text>
                    <Markdown className="markdown" rehypePlugins={[rehypeRaw]}>
                      {SelectedWorkObj?.description}
                    </Markdown>
                  </DrawerBody>
                </DrawerContent>
              </Drawer>
              <VStack>
                {user?.other_projects?.length ? <Flex
                  position={'relative'}
                  alignItems={"flex-start"}
                  direction="column"
                  gap={"30px"}
                  p={3}
                  w={{ base: '100%', md: "300px" }}
                >
                  <HStack>
                    <Text color={user?.theme[1]} fontSize={"xl"} fontWeight={"500"}>Projects</Text>
                    {!extUser && <Box cursor={'pointer'} onClick={onOpenEditProject}>
                      <PiPlusCircleDuotone size="18px" />
                    </Box>}
                  </HStack>
                  {user?.other_projects.map((item, index) => {
                    return (
                      <VStack
                        w={"100%"}
                        gap={3}
                        alignItems={"flex-start"}
                        position={'relative'}
                        key={index}>
                        <Text fontWeight={"500"}
                          fontSize={"sm"}
                          width={'fit-content'}
                        >
                          {item.title}
                        </Text>

                        <Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'}>
                          <Markdown className="markdown" rehypePlugins={[rehypeRaw]}>
                            {item.summary}
                          </Markdown>
                        </Box>
                        <HStack wrap={"wrap"} gap={2}>
                          {item?.images.length > 0 && item.images.map((item, index) => {
                            return (
                              <Image src={item} key={index} maxHeight={"100px"} maxWidth="100px" objectFit={"contain"} />
                            )
                          })}
                        </HStack>
                        <HStack gap={2} w="100%">
                          <Timeline date={String(item.year)} />
                          <Box
                            cursor={"pointer"}
                            onClick={() => {
                              onOpenProjectDescription();
                              setSelectedProjectObj(item);
                            }}
                          >
                            <PiTextColumnsDuotone size="16px" />
                          </Box>
                          {!extUser &&
                            <Box
                              cursor={"pointer"}
                              onClick={() => {
                                setEditingProjectObjIndex(index);
                                onOpenEditProject();
                                setSelectedProjectObj(item);
                                resetFormWithProjectIdData(item)
                              }}
                            >
                              <PiPencilCircleDuotone size="18px" />
                            </Box>
                          }
                        </HStack>
                      </VStack>
                    );
                  })}
                </Flex> : null}
                <ProjectEdit {...editProject} />
                <Drawer
                  blockScrollOnMount={false}
                  isOpen={isOpenProjectDescription}
                  onOpen={onOpenProjectDescription}
                  onClose={() => {
                    onCloseProjectDescription();
                    setSelectedProjectObj(null);
                  }}
                >
                  <DrawerOverlay />
                  <DrawerContent
                    border={"2px solid"}
                    borderColor={getTextColor(user?.theme[1]) ? "#fff" : "#000"}
                    background={user?.theme[1]} zIndex={9999} maxWidth={{ base: "90%", md: "50%" }}>
                    <DrawerCloseButton color={getTextColor(user?.theme[1]) ? "#fff" : "#000"} />
                    <DrawerHeader color={getTextColor(user?.theme[1]) ? "#fff" : "#000"} textAlign={"center"} fontSize={'sm'} fontWeight={'500'} borderBottom={"2px solid"} borderColor={getTextColor(user?.theme[1]) ? "#fff" : "#000"}>
                      {SelectedProjectObj?.title}
                    </DrawerHeader>
                    <DrawerBody color={getTextColor(user?.theme[1]) ? "#fff" : "#000"} background={user?.theme[1]} className="app-scroll" overflow={'auto'} p={"20px 40px"} width={"100%"}>
                      {SelectedProjectObj?.images.length > 0 && <HStack className="app-scroll" mt={2} mb={4} overflowX={"auto"} gap={2}>
                        {SelectedProjectObj.images.map((item, index) => {
                          return (
                            <Image src={item} key={index} maxHeight={"350px"} objectFit={"contain"} />
                          )
                        })}
                      </HStack>
                      }
                      <Markdown className="markdown" rehypePlugins={[rehypeRaw]}>
                        {SelectedProjectObj?.description}
                      </Markdown>
                    </DrawerBody>
                  </DrawerContent>
                </Drawer>
                {user?.achievements?.length ? <Flex
                  position={'relative'}
                  height={"fit-content"}
                  alignItems={"flex-start"}
                  direction="column"
                  p={3}
                  w={"100%"}
                  gap="30px"
                >
                  <HStack w={"100%"}>
                    <Text color={user?.theme[1]} fontSize={"xl"} fontWeight={"500"}>Achievements</Text>
                    {!extUser && <Box cursor={'pointer'} onClick={onOpenEditAchievements}>
                      <PiPlusCircleDuotone size="18px" />
                    </Box>}
                  </HStack>
                  {user?.achievements.map((item, index) => {
                    return (
                      <VStack
                        gap={3}
                        alignItems={"flex-start"}
                        width={"100%"}
                        position={'relative'}
                        key={index}>
                        <Text
                          width={'fit-content'}
                        >
                          {item.title}
                        </Text>
                        <HStack gap={2} w="100%">
                          <Timeline date={String(item.year)} />
                          {!extUser &&
                            <Box
                              cursor={"pointer"}
                              onClick={() => {
                                setEditingAchievementObjIndex(index);
                                onOpenEditAchievements();
                                resetFormWithAchievementIdData(item)
                              }}
                            >
                              <PiPencilCircleDuotone size="18px" />
                            </Box>
                          }
                        </HStack>
                      </VStack>
                    );
                  })}
                </Flex> : null}
                <AchievementsEdit {...editAchievements} />

                {user?.education?.length ? <Flex
                  position={'relative'}
                  direction={"column"}
                  gap="30px"
                  p={3}
                  w={"100%"}
                >
                  <HStack>
                    <Text color={user?.theme[1]} fontSize={"xl"} fontWeight={"500"}>Education</Text>
                    {!extUser && <Box
                      cursor={"pointer"}
                      onClick={() => {
                        onOpenEditEducation();
                        reset(InitialFormValue);
                      }}
                    >
                      <PiPlusCircleDuotone size="18px" />
                    </Box>}
                  </HStack>
                  {user?.education.map((item, index) => {
                    return (
                      <VStack
                        gap={3}
                        alignItems={"flex-start"}
                        position={'relative'}
                        key={index}>
                        <Text fontWeight={"500"}
                          fontSize={"sm"} >{item.name}</Text>
                        <Text
                          width={'fit-content'}
                          mt={"-3px"}
                        >
                          {item.course}
                        </Text>
                        <Markdown className="markdown" rehypePlugins={[rehypeRaw]}>
                          {item.description}
                        </Markdown>
                        <HStack gap={2} w="100%" alignItems={"start"}>
                          <Timeline date={String(item.year)} />
                          {!extUser &&
                            <Box
                              cursor={"pointer"}
                              onClick={() => {
                                setEditingEducationObjIndex(index);
                                onOpenEditEducation();
                                resetFormWithEducationIdData(item)
                              }}
                            >
                              <PiPencilCircleDuotone size="18px" />
                            </Box>
                          }
                        </HStack>
                      </VStack>
                    );
                  })}

                </Flex> : null}
                <EducationEdit {...editEducation} />

              </VStack>
              <VStack>
                {user?.core_skills?.length ? <Box
                  w={{ base: '100%', md: "auto" }}
                  position={'relative'}
                  height={'fit-content'}
                  p={3}
                >
                  <HStack>
                    <Text color={user?.theme[1]} fontSize={"xl"} fontWeight={'500'} >Core Skills</Text>
                    {!extUser && <Box cursor={'pointer'} onClick={onOpenCoreSkills}>
                      <PiPlusCircleDuotone size="18px" />
                    </Box>}
                  </HStack>
                  <Wrap spacing={0} mt={'30px'}>
                    {user?.core_skills?.map((item, index) => (
                      <WrapItem key={index}>
                        <Tag
                          borderRadius="full"
                          fontWeight={400}
                          backgroundColor="transparent"
                          p={0}
                          pr={2}
                        >
                          <TagLabel fontSize="sm" fontWeight={500}>{item}</TagLabel>
                        </Tag>
                      </WrapItem>
                    ))}
                  </Wrap>
                </Box> : null}
                <CoreSkillsEdit {...editCoreSkills} />
                {user?.soft_skills?.length ? <Box

                  position={'relative'}
                  height={'fit-content'}
                  p={3}
                >
                  <HStack>
                    <Text color={user?.theme[1]} fontSize={"xl"} fontWeight={'500'}>Soft Skills</Text>
                    {!extUser && <Box cursor={'pointer'} onClick={onOpenSoftSkills}>
                      <PiPlusCircleDuotone size="18px" />
                    </Box>}
                  </HStack>
                  <Wrap spacing={0} mt={'30px'}>
                    {user?.soft_skills?.map((item, index) => (
                      <WrapItem key={index}>
                        <Tag
                          borderRadius="full"
                          fontWeight={400}
                          p={0}
                          pr={2}
                          backgroundColor="transparent"
                        >
                          <TagLabel fontSize="sm" fontWeight={500}>{item}</TagLabel>
                        </Tag>
                      </WrapItem>
                    ))}
                  </Wrap>
                </Box> : null}
                <SoftSkillsEdit {...editSoftSkills} />
              </VStack>
            </HStack>
          </Flex>
          <UserCardEditable isOpen={isUserCardOpen} onClose={onUserCardClose} onOpen={onUserCardOpen} />
        </>
      )}
    </Flex>
  )
}

export default Bold;