import { Box, Drawer, DrawerOverlay, DrawerContent, DrawerHeader, DrawerBody, DrawerCloseButton, Text, Image, VStack, HStack } from '@chakra-ui/react';
import React, { useState } from 'react';
import BasicImage from '../../assets/templates/Basic.png'
import MonochromVintageImage from '../../assets/templates/Monochrome Vintage.png'
import GalleryImage from '../../assets/templates/Gallery.png'
import SolidBackgroudImage from '../../assets/templates/Solid Background.png'
import OverlayPersonaImage from '../../assets/templates/Overlay Persona.png';
import BoldImage from '../../assets/templates/Bold.png';
import { useSelector } from 'react-redux';
import { BsPatchCheckFill } from 'react-icons/bs';
import { getTextColor } from '../../helper/random.helper';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase.config';
import { login } from '../../redux/slices/userSlice';
import { useDispatch } from 'react-redux';
import { PiInfoDuotone } from 'react-icons/pi';

const TemplateDrawer = ({ isOpen, onOpen, onClose }) => {

  const { user } = useSelector(state => state.user);
  const dispatch = useDispatch();

  const [TemplatesList, setTemplatesList] = useState([
    { name: "Basic", image: BasicImage, bgColor: false },
    { name: "Monochrome Vintage", image: MonochromVintageImage, bgColor: false },
    { name: 'Solid Background', image: SolidBackgroudImage, bgColor: true },
    { name: "Gallery", image: GalleryImage, bgColor: false },
    { name: "Overlay Persona", image: OverlayPersonaImage, bgColor: false },
    { name: "Bold", image: BoldImage, bgColor: false }
  ])

  const ChangeTheme = async (template) => {
    try {
      let newData = {
        ...user,
        theme: [template.name, user?.theme?.[1], template.bgColor]
      };
      // Update the user's document in Firestore with the new data
      const userDocRef = doc(db, "users", user?.uid);
      await updateDoc(userDocRef, newData);
      // Dispatch the updated user data to the store
      dispatch(login({ ...user, theme: [template.name, user?.theme?.[1], template.bgColor] }));
    } catch (error) {
      console.error("Error updating user data: ", error);
    }
  }


  return (
    <Box>
      <Drawer
        blockScrollOnMount={false}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
      >
        <DrawerContent
          border={"1px solid"}
          borderColor={"#E8E8E8"}
          borderRadius={"5px 0px 0px 5px"} background={"#fff"} zIndex={99999} maxWidth={{ base: "90%", md: "60%", lg: "45%" }}>
          <DrawerCloseButton />
          <DrawerHeader textAlign={"center"} fontSize={'sm'} fontWeight={'500'} borderBottom={"1px solid"} borderColor={"#E8E8E8"}>
            Select Template
          </DrawerHeader>
          <DrawerBody className="app-scroll" overflow={'auto'} p={{ base: "20px 10px", md: "20px 40px" }} width={"100%"}>
            <VStack spacing={2}>
              {
                TemplatesList.map((template, index) => (
                  <VStack w="100%" onClick={() => ChangeTheme(template)} cursor={"pointer"} spacing={1} key={index} p={2} border={"2px solid"} borderColor={"#E8E8E8"} rounded={"5px"}
                    _hover={
                      {
                        backgroundColor: user?.theme?.[1],
                        color: getTextColor(user?.theme?.[1]) ? 'transparent' : 'brand.black'
                      }
                    }>
                    <Image borderRadius={"5px"} border={"2px solid"} borderColor={"#E8E8E8"} src={template.image} alt={template.name} />
                    <HStack p={1} width={"100%"} backgroundColor={getTextColor(user?.theme?.[1]) ? 'brand.white' : 'brand.black'} justifyContent={"center"}>
                      {user?.theme?.[0] === template.name && <BsPatchCheckFill color={user?.theme?.[1]} />}
                      <Text color={!getTextColor(user?.theme?.[1]) ? 'brand.white' : 'brand.black'}>{template.name}</Text>
                    </HStack>
                  </VStack>
                ))
              }
              <HStack p={2} width={"100%"} backgroundColor={"#F4F4F4"} justifyContent={"center"}>
                <PiInfoDuotone color={"#303030"} />
                <Text fontSize={"sm"} textAlign="center" color={"#303030"}>Adding new templates every week</Text>
              </HStack>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  )
}

export default TemplateDrawer